<template>
  <div />
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

const { authHeaders } = require('../../utils')

const CORE_API_URL = process.env.VUE_APP_CORE_API_URL

export default {
  async mounted() {
    const appLoading = document.getElementById('loading-bg')

    // Show spinner
    if (appLoading) appLoading.style.display = 'block'

    // OAuth logic
    const { tool } = this.$route.params
    const { code } = this.$route.query
    console.log(this.$route.params, this.$route.query)
    try {
      const url = `${CORE_API_URL}/auth/tool/oauth-cb/${tool}?code=${code}`
      await axios.post(url, {}, authHeaders())
    } catch (error) {
      console.error(error)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'An error occurred',
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      })
    }

    // Hide spinner
    if (appLoading) appLoading.style.display = 'none'

    // Redirect to Tools & Apps page
    this.$router.push(`/features/tools-apps?tool=${tool}`)
  },
}
</script>
